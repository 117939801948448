import React from "react";
import { FaTelegram, FaTwitter } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/ekslogo.jpg";
import elon from "../assets/eks.jpg";
import kasongo from "../assets/eks1.jpg";
import { FaCopyright } from "react-icons/fa";

const ESK = () => {
  return (
    <div>
      <div className="header flex justify-between align-items-center">
        <h1 className="text-primary">Elon Kasongo</h1>
        <div
          className="d-flex align-items-center justify-content-between gap-2"
          style={{ height: "50px" }}
        >
          <h1 className="text-danger mt-3">$EKS </h1>
          <img
            src={logo}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            alt="logo"
          />
        </div>
      </div>

      <div className="container-fluid mt-5 mb-3">
        <div className="rowww">
          <div className="row aboutt">
            <div
              data-aos="fade-in"
              className="col-9 m-auto d-flex p-sm-3 pt-3 text-dark"
            >
              <div>
                <h3 className="mt-5 mb-sm-2 mx-sm-0 text-center">
                  About <span className="text-primary">$EKS</span>
                </h3>
                <p className="about text-center">
                  Elon Kasongo emerges as a formidable and dynamic force within
                  the meme landscape, characterized by unwavering confidence and
                  determination. Distinguished by remarkable speed and
                  adaptability, Kasongo stands out among its contemporaries,
                  posing a significant challenge to established figures. With
                  exceptional potential and an innovative spirit, this entity
                  represents a powerful combination of creativity and boldness.
                  Kasongo's arrival in this domain is designed to disrupt
                  expectations and command attention, signaling a transformative
                  presence that transcends conventional boundaries.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 meme">
          <div className="socials d-flex justify-content-center gap-4 ">
            <a href="https://t.me/+tDrMpzBGbd4xOTc0" className="tg">
              <FaTelegram size={"50px"} color="lilac" />
            </a>

            <a href="https://x.com/ElonKasongoSol" className="twitter">
              <FaTwitter color="royalblue" size={"50px"} />
            </a>
          </div>
        </div>

        <div className="container mt-5 p-0 d-flex justify-between gap-5 align-items-center flex-column">
          <h3>Hi guys,meet Elon Kasongo</h3>
          <div
            className="d-flex flex-row flex-nowrap gap-3"
            style={{ maxWidth: "100%", overflow: "scroll" }}
          >
            <img
              src={elon}
              className="me-2"
              style={{ width: "300px", height: "300px" }}
              alt="logo"
            />
            <img
              src={kasongo}
              className="me-2"
              style={{ width: "300px", height: "300px" }}
              alt="logo"
            />
            <img
              src={logo}
              className="me-2"
              style={{ width: "300px", height: "300px" }}
              alt="logo"
            />
          </div>
        </div>

        <div className="roadmap mt-5 d-flex justify-between gap-3 align-items-center flex-column">
          <h1>$EKS's Roadmap</h1>
          <div className="">
            <p>
              - <span className="fw-bold">Announcement & Transparency:</span>{" "}
              Release a detailed statement addressing the project, outlining the
              steps we’re taking to launch $ESK on sol.
            </p>
            <p>
              <span className="fw-bold">
                - Media Outreach & Partnership Announcements:
              </span>{" "}
              Partner with industry influencers and media outlets to spread the
              word about our launch. Share articles, interviews, and updates
              across social media platforms to increase visibility and generate
              excitement.
            </p>
            <p>
              <span className="fw-bold">- Community Engagement:</span> Community
              Engagement Campaigns Launch contests, AMAs (Ask Me Anything), and
              educational webinars to engage the community. Offer rewards for
              sharing information about the launch, referring friends, and
              participating in discussions.
            </p>
            <p>
              <span className="fw-bold">- Promotion & Engagement:</span>Run
              targeted ads, influencer campaigns, and social media promotions to
              maximize reach and participation. Host live events,and twitter
              spaces.
            </p>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center my-5 gap-2">
          <FaCopyright />
          <p className="m-0">Copyright 2024 Elon Kasongo on Sol.</p>
        </div>
      </div>
    </div>
  );
};

export default ESK;
