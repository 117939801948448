import logo from './logo.svg';
import './App.css';
import ESK from '../src/Components/ESK.tsx';

function App() {
  return (
    <div className="">
         <ESK/>
    </div>
  );
}

export default App;
